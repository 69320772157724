import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
const routes = [{
	path: '/',
	name: 'index',
	component: () => import('@/pages/HomeIndex')
}, {
	path: '/about',
	name: 'about',
	component: () => import('@/pages/AboutPage')
}, {
	path: '/product',
	name: 'product',
	component: () => import('@/pages/ProductPage')
},{
	path: '/charitable',
	name: 'charitable',
	component: () => import('@/pages/CharitablePage')
},{
	path: '/news',
	name: 'news',
	component: () => import('@/pages/NewsPage')
},{
	path: '/newsDetail',
	name: 'newsDetail',
	component: () => import('@/pages/NewsDetail')
}, ]

const router = new VueRouter({
	routes
});

export default router
