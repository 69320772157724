<template>
	<div id="app"><router-view></router-view></div>
</template>

<script>
export default {
	name: 'App',
	created() {
		let _this = this
		_this.isPc()
		window.addEventListener('resize', function() {
			_this.isPc()
		})
	},
	methods: {
		isPc() {
			if (document.documentElement.clientWidth >= 1000) {
				location.href = 'https://www.422wsdq.com/'
				// location.href = 'http://192.168.1.171:3000/'
			}
		}
	}
}
</script>

<style>
html {
	font-size: 62.5%;
}
#app {
	overflow-x: hidden;
}
</style>
