import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';
import '@/assets/css/comm.css'
import AmapVue from '@amap/amap-vue'
import VueAnimateOnScroll from 'vue-animate-onscroll'
import router from '@/router/index.js'
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

AmapVue.config.key = '4de4580badedccd29670b6eab637f537'

Vue.use(AmapVue)
Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper)
Vue.use(VueAnimateOnScroll)

Vue.config.productionTip = false

new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
